<template>
	<div>
		<label v-if="show_title">{{ $t('monte.reservation.reservation_semence') }}</label>
		<CustomTable
			id_table="contract_monte_resa"
			primaryKey="id"
			:items="items"
			:busy.sync="table_busy"
			:hide_if_empty="true"
			:hrefsRoutes="config_table_hrefs"
		/>

		<ModalAddResa
			ref="modalAddResa"
			:contract_id="contract_id"
			:season="season"
			:stallion="stallion"
			:available_tiers="available_tiers"
			:available_mares="available_mares"
			:available_centre_stockage="available_centre_stockage"
			:mare.sync="mare"
			:cmep.sync="cmep"
			@ok="init_component"
		/>
	</div>
</template>

<script type="text/javascript">
	import Common from '@/assets/js/common'
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"
	import Navigation from "@/mixins/Navigation.js"

	export default {
		name: "ReservationList",
		mixins: [MonteReservationOrder, Navigation],
		props: {
			contract_id: { type: Number },
			supplier_id: { type: Number, default: 0 }, // tiers_id de l'expéditeur
			season: { type: Object, default: null },
			stallion: { type: Object, default: null },
			available_tiers: { type: Array, default: () => [] },
			available_mares: { type: Array, default: () => [] },
			available_centre_stockage: { type: Array, default: () => [] },
			show_title: { type: Boolean, default: true },
			reservations: { type: Array, default: () => [] },
			mare: { type: Object, default: null },
			cmep: { type: Object, default: null }
		},
		data () {
			return {
				table_busy: false,
				items: [],
				monteresa_id: 0,
				first_in: true,
	
				events_tab: {
				    'TableAction::goToAddMonteResa' : this.formAddResa,
					'TableAction::goToEditMonteResa': this.formEditResa,
					'TableAction::goToDeleteResa': this.deleteResa
				},
				config_table_hrefs: {
				    'expediteur.tiers_rs': {
						routeUniqueName: 'expediteurFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'expediteur.tiers_id'
						}
					},
					'destinataire.tiers_rs': {
						routeUniqueName: 'destinataireFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'destinataire.tiers_id'
						}
					}
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.table_busy = true

				if(this.supplier_id !== 0) {
					this.items = await this.getSupplierSailliesReservations(this.supplier_id)
				}
				else if(this.reservations && this.reservations.length > 0 && this.first_in) {
					this.items = this.reservations
					this.first_in = false
				}
				else {
					this.items = await this.getContractSailliesReservations(this.contract_id)
				}

				this.table_busy = false
			},
			formAddResa() {
				this.$refs.modalAddResa.openModal()
			},
			formEditResa(resas) {
				this.$refs.modalAddResa.openModal(resas[0].id)
			},
			async deleteResa(resas) {
				await Common.asyncForEach(resas, async (resa) => {
					this.deleteSaillieReservation(resa.id)
				}).then(async () => {
					await this.$sync.runOptionalWhishlist(['monte_reservation'])
				}).then(() => {
					this.init_component()
				})
			}
		},
		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ModalAddResa : () => import('@/components/Contract/Reservation/ModalAdd'),
		}
	}
</script>
